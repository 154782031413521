import { persistCombineReducers } from 'redux-persist';
import { createFilter } from 'redux-persist-transform-filter';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage

import mainReducer from './main';
import userReducer from './user';
import papimReducer from './papim';

const userWhiteList = [
  'account',
];


const saveUserFilter = createFilter(
  'user',
  userWhiteList,
);

const persistConfig = {
  key: 'root',
  storage,
  transforms: [saveUserFilter],
  whitelist: ['user'],
};

const rootReducer = {
  user: userReducer,
  main: mainReducer,
  papim: papimReducer,
};

export default persistCombineReducers(persistConfig, rootReducer);
