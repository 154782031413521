import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Switch, Route, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { hideError } from 'reducers/papim';


import MastNavSNCF from 'common/BootstrapSNCF/MastNavSNCF';
import MastNavItemSNCF from 'common/BootstrapSNCF/MastNavItemSNCF';
import NavBarSNCF from 'common/BootstrapSNCF/NavBarSNCF';
import logo from 'assets/logo-DGEXSOL-notext.svg';
import DashboardPapim from 'applications/papim/views/Dashboard';
import './papim.scss'

export default function Home() {
  const dispatch = useDispatch();
  const { error } = useSelector((state) => state.papim);

  function closeError() {
    dispatch(hideError())
  }

  return (
    <>
    <CSSTransition
        in={error}
        timeout={300}
        classNames="alert"
        unmountOnExit
      >
        <div className="form-error mb-3">
          <h2 className="text-uppercase">Une erreur vient de se produire</h2>
          <ul className="mt-1 ">
            <li>Merci de rafraichîr votre page</li>
            <li>Contacter votre service utilisateur</li>
          </ul>
          <button type="button" onClick={closeError} className="btn btn-outline-light btn-sm">Fermer</button>
        </div>
      </CSSTransition>
      <MastNavSNCF
        items={(
          <>
            <MastNavItemSNCF
              link="/dashboard"
              linkname="Tableau de bord"
              icon="icons-localisation-pin"
            />
          </>
            )}
      />
      <NavBarSNCF appName="PAPIM" logo={logo} />
      <Switch>
        <Route exact path="/dashboard">
          <DashboardPapim />
        </Route>
        <Redirect to="/dashboard" />
      </Switch>
    </>
  );
}
