import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { withTranslation } from 'react-i18next';
import nextId from 'react-id-generator';
import { getEndpoints, getEndpoint, getApplications, APPLICATION_PARAMS } from 'reducers/papim';
import Pagination from 'common/BootstrapSNCF/PaginationSNCF';
import '../papim.scss';
//import endpoints from './data';
import SelectSNCF from 'common/BootstrapSNCF/SelectSNCF';
import { CSSTransition } from 'react-transition-group';



const REQUEST_LIMIT = 20;
const INITIAL_SELECTED_APPLICATION = {
  [APPLICATION_PARAMS.listParam]: undefined,
};

const ALL_OPTION = {
  key: 'all',
  label: 'Toutes',
};

function Dashboard(props) {
  const dispatch = useDispatch();
  const endpoints = useSelector((state) => state.papim.endpoints);
  const endpoint = useSelector((state) => state.papim.endpoint);
  const applicationList = useSelector((state) => state.papim.applications);
  const showBody = useSelector((state) => state.papim.showBody);

  const [selectedApplication, setSelectedApplication] = useState(INITIAL_SELECTED_APPLICATION);
  const [activePage, setActivePage] = useState(1);
  const [firstPage, setFirstPage] = useState(0);
  const [page, setPage] = useState(1);
  const [rangeIndex, setRangeIndex] = useState(0);

  const tableHeader = ['Aperçu', 'Méthode', 'Application', 'Statut'];
  const redDot = <i className="text-danger icons-slider-on mr-1" />;
  const yellowDot = <i className="text-warning icons-slider-on mr-1" />;
  const greenDot = <i className="text-success icons-slider-on mr-1" />;
  const greyDot = <i className="text-secondary icons-slider-on mr-1" />;

  const pages = endpoints.count % REQUEST_LIMIT !== 0 ? Math.floor(endpoints.count / REQUEST_LIMIT)
    + 1 : endpoints.count / REQUEST_LIMIT;

  useEffect(() => {
    dispatch(getEndpoints(page, selectedApplication));
  }, [dispatch, page, selectedApplication]);

  useEffect(() => {
    dispatch(getApplications());
  }, []);

  const applicationListOptions = [ALL_OPTION, ...applicationList];

  const getPageContent = (e, pages, paginationRange) => {
    const index = e.currentTarget.getAttribute('href').split('#')[1];
    const limitPages = paginationRange < pages ? paginationRange : pages;

    switch (index) {
      case 'first':
        setActivePage(1);
        setFirstPage(0);
        setPage(1);
        break;
      case 'last':
        setActivePage(pages);
        setFirstPage(pages - limitPages);
        setPage((pages));

        break;
      case 'previous':
        setActivePage(activePage - 1);
        if (firstPage > 0) {
          setFirstPage(firstPage - 1);
        }
        setPage(activePage - 1);
        break;
      case 'next':
        setActivePage(activePage + 1);
        if (activePage + 1 === limitPages
          + firstPage && activePage + 1 < pages && limitPages !== pages) {
          setFirstPage(firstPage + 1);
        }
        setPage(activePage + 1);
        break;
      default:
        setPage(index);
        setActivePage(Number(index));
        if (Number(index) === limitPages
          + firstPage && Number(index) > activePage && Number(index) < pages) {
          setFirstPage(firstPage + 1);
        }
        if (firstPage > 0 && Number(index) < activePage) {
          setFirstPage(firstPage - 1);
        }

    }
  };

  function statusColor(status) {
    switch (status) {
      case 'REFUSED':
      case 'TO_BE_APPROVED':
        return redDot;
      case 'DEVELOPING':
      case 'IN_REVIEW':
        return yellowDot;
      case 'DEPLOYED_DEV':
      case 'DEPLOYED_STAGING':
      case 'DEPLOYED_PROD':
        return greenDot;
      default:
        return greyDot;
    }
  }

  function badgeColor(method) {
    switch (method) {
      case 'GET':
        return 'badge-get';
      case 'POST':
        return 'badge-post';
      case 'PUT':
        return 'badge-puy';
      case 'OPTION':
        return 'badge-options';
      case 'PATCH':
        return 'badge-patch';
      case 'DELETE':
        return 'badge-delete';
      case 'BASIC':
        return 'badge-basic';
      case 'LINK':
        return 'badge-link';
      case 'HEAD':
        return 'badge-head';
      default:
        return 'badge-secondary';
    }
  }

  function goToNext() {
    setRangeIndex((rangeIndex + 1) % 3)
  }

  function onSelectApplication(param, value) {
    setPage(1);
    setActivePage(1);
    setFirstPage(0);
    setSelectedApplication({
      ...selectedApplication,
      [param]: value,
    });
  }

  function onSelectEndpoint(id) {
    dispatch(getEndpoint(id))
  }

  return (
    <>
      <div className="actionbar papim">
        <div className="actionbar-head">
          <h1 className="mb-0">{props.t('papim.tableTitle')}</h1>
          <ul className="toolbar mb-0 d-none d-md-flex">
            <li className="toolbar-item d-flex align-items-center toolbar-item-spacing">
              <span>
                {endpoints.count}
                {' '}
                {props.t('papim.endpoints')}
              </span>
            </li>
          </ul>
        </div>
      </div>
      <main className="mastcontainer">
        <div className="row pb-3 pl-2 bg-white">
          <div className="col-3 pr-3">
            <SelectSNCF
              id={`select-params-${nextId()}`}
              title="Applications"
              options={applicationListOptions}
              selectedValue={selectedApplication[APPLICATION_PARAMS.listParam] || ALL_OPTION}
              onChange={(e) => onSelectApplication(APPLICATION_PARAMS.listParam, e.currentTarget.value)}
              selectStyle="cell-inner papim pr-6"
              labelKey="label"
            />
          </div>
        </div>
        <div className="" id="accordionExample">
          <div className="table-wrapper">
            <div className={`table-scroller last-cell-fixed dragscroll fixHeader vh-70`}>
              <table className="table border">
                <thead className="thead thead-light">
                  <tr>
                    {tableHeader.map((tb) => (
                      <th scope="col" key={nextId()} data-id="test">
                        <div className="cell-inner papim">{tb}</div>
                      </th>

                    ))}
                  </tr>
                </thead>
                <CSSTransition
                  in={showBody}
                  timeout={300}
                  classNames="alert"
                  unmountOnExit
                >
                  <tbody>
                    {endpoints.results && endpoints.results.map((e) => (
                      <>
                        <tr data-toggle="collapse" data-target={`#className-${e.id}`} aria-expanded="true" aria-controls="collapseOne" onClick={(event) => onSelectEndpoint(e.id)}>
                          <td><div className="cell-inner papim font-weight-bold">{e.summary ? `${e.summary}` : 'Non disponible'}</div></td>
                          <td><div className="cell-inner papim"><span className={`badge ${badgeColor(e.method)}`}>{e.method}</span></div></td>
                          <td><div className="cell-inner papim text-capitalize font-weight-bold">{e.api}</div></td>
                          <td><div className="cell-inner papim">{statusColor(e.state)}<span className="tab">{e.state}</span></div>
                          </td>
                        </tr>
                        {endpoint && endpoint.map((d) => (
                          <tr >
                            <td colSpan="4" className="border-0 px-0">
                              <div id={`className-${e.id}`} className="collapse border-top" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="d-flex">
                                  <div className="ml-5 my-2 w-10 vertical-center">{d.realm}</div>
                                  <div className="my-2 mr-5 vertical-center">Version {d.version}</div>
                                  <div className="my-2 mr-2 vertical-center" onClick={goToNext}><span className={`badge badge-success font-weight-bold`}>[{d?.stats[rangeIndex]?.range}]</span></div>
                                  <div className="my-2 mr-4 vertical-center">Latence Moy.: {d?.stats[rangeIndex]?.latency_average} ms</div>
                                  <div className="my-2 mr-4 vertical-center">Nbre de requêtes.: {d?.stats[rangeIndex]?.request_count} / Nbre d'erreurs: {d?.stats[rangeIndex]?.error_count}</div>
                                  <div className="my-2 mr-5 vertical-center">Taille requête: {d?.stats[rangeIndex]?.request_size} ko / Taille réponse: {d?.stats[rangeIndex]?.response_size} ko</div>
                                  <div className="my-2 mr-5 vertical-center"><span className={`badge badge-secondary`}>{d.permission}</span></div>
                                  <div className="my-2 mr-5 align-middle"><a href={d.doc_url} target="_blank">Documentation</a></div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </>
                    ))}
                  </tbody>
                </CSSTransition>

              </table>
            </div>
          </div>

        </div>
        <div className="d-flex justify-content-center">
          <Pagination pages={pages} activePage={activePage} firstPage={firstPage} getPageContent={getPageContent} />
        </div>
      </main>
    </>
  );
}

const DashboardPapim = withTranslation()(Dashboard);

export default DashboardPapim;
