/* eslint-disable default-case */
import produce from 'immer';
import { get } from 'common/requests';

export const GET_ENDPOINTS = 'papim/GET_ENDPOINTS';
export const GET_ENPOINTS_ERROR = 'papim/GET_ENPOINTS_ERROR';
export const GET_APPLICATIONS = 'papim/GET_APPLICATIONS';
export const GET_BODY = 'papim/GET_BODY';
export const GET_ERROR = 'cerbere/GET_ERROR';
export const GET_ENDPOINT = 'cerbere/GET_ENDPOINT';





const API_URLS = {
  endpoints: '/endpoint_tracker/endpoints/',
  applications: '/endpoint_tracker/api/'
};

export const APPLICATION_PARAMS = {
  listParam: 'api',
};

const initialState = {
  endpoints: {},
  endpoint: [
    {
       "environment": "DEV",
       "version": "2.0",
       "stats":[
          {
             "range":"1 h",
             "latency_average":"300",
             "request_count":"50",
             "error_count":"40",
             "request_size":"200",
             "response_size":"500"
          },
          {
            "range":"1 jour",
            "latency_average":"600",
            "request_count":"50",
            "error_count":"40",
            "request_size":"200",
            "response_size":"500"
         },
         {
            "range":"1 sem.",
            "latency_average":"900",
            "request_count":"50",
            "error_count":"40",
            "request_size":"200",
            "response_size":"500"
         },
       ],
       "permission":403,
       "doc_link":"https://docs.dev.dgexsol.fr/chartis.html"
    },
    {
        "environment": "STAGING",
        "version": "2.0",
        "stats":[
            {
                "range":"1 h",
                "latency_average":"300",
                "request_count":"50",
                "error_count":"40",
                "request_size":"200",
                "response_size":"500"
             },
             {
               "range":"1 jour",
               "latency_average":"600",
               "request_count":"50",
               "error_count":"40",
               "request_size":"200",
               "response_size":"500"
            },
            {
               "range":"1 sem.",
               "latency_average":"900",
               "request_count":"50",
               "error_count":"40",
               "request_size":"200",
               "response_size":"500"
            },
        ],
        "permission":403,
        "doc_link":"https://docs.dev.dgexsol.fr/chartis.html"
     },
     {
        "environment": "PROD",
        "version": "2.0",
        "stats":[
            {
                "range":"1 h",
                "latency_average":"300",
                "request_count":"50",
                "error_count":"40",
                "request_size":"200",
                "response_size":"500"
             },
             {
               "range":"1 jour",
               "latency_average":"600",
               "request_count":"50",
               "error_count":"40",
               "request_size":"200",
               "response_size":"500"
            },
            {
               "range":"1 sem.",
               "latency_average":"900",
               "request_count":"50",
               "error_count":"40",
               "request_size":"200",
               "response_size":"500"
            },
        ],
        "permission":403,
        "doc_link":"url"
     }
 ],
  applications: [],
  error: false,
  showBody: false,
};


export default function reducer(state = initialState, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case GET_ENDPOINTS:
        draft.endpoints = action.endpoints;
        break;
      case GET_APPLICATIONS:
        draft.applications = action.applications;
        break;
        case GET_BODY:
        draft.showBody = action.showBody;
        break;
      case GET_ENPOINTS_ERROR:
        draft.error = action.error;
        break;
        case GET_ENDPOINT:
        draft.endpoint = action.endpoint;
        break;
        case GET_ERROR:
        draft.error = action.error;
        break;
        case initialState:
        return initialState
    }
  });
}

export const getEndpoints = (page, selectedApplication) => async (dispatch) => {
  dispatch({
    type: GET_BODY,
    showBody: false,
  });
  try {
    const params = {
      page: page,
    };
    if (selectedApplication) {
      Object.entries(selectedApplication).forEach(([k, v]) => {
        try {
          JSON.parse(v);
        } catch (e) {
          params[k] = v;
        }
      });
    }
    const endpoints = await get(API_URLS.endpoints, params);
    dispatch({
      type: GET_ENDPOINTS,
      endpoints,
    });
    dispatch({
      type: GET_BODY,
      showBody: true,
    });
    return endpoints;
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    });
    return error;
  }
};

export const getEndpoint = (id) => async (dispatch) => {
  try {
    const endpoint = await get(`${API_URLS.endpoints}${id}/`);
    dispatch({
      type: GET_ENDPOINT,
      endpoint,
    });
    return endpoint
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    });
    return error;
  }
}

export const getApplications = () => async (dispatch) => {
  try {
    const applications = await get(API_URLS.applications);
    dispatch({
      type: GET_APPLICATIONS,
      applications,
    });
    return applications;
  } catch (error) {
    dispatch({
      type: GET_ERROR,
      error: true,
    });
    return error;
  }
};

export const hideError = () => async (dispatch) => {
  dispatch({
    type: GET_ERROR,
    error: false,
  });
};
