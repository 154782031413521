export const MAIN_API = {
  proxy: 'https://gateway.shared.dgexsol.fr',
  api: 'https://gateway.shared.dgexsol.fr',
  version: '0.0.1.20200408.1',
};

export const PORTAL_URL = {
  url: 'https://dgexsol.fr/',
};

export default MAIN_API;
